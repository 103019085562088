/*头像效果-start*/

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

.ih-item.circle.effect {
  margin: 0 auto;
  -webkit-perspective: 900px;
  -moz-perspective: 900px;
  perspective: 900px;
}
.ih-item.circle.effect .img {
  z-index: 11;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.ih-item.circle.effect .info {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.ih-item.circle.effect .info .info-back {
  opacity: 1;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: #333333;
}

.ih-item.circle.effect .info h2 {
  color: #fff;    
  position: relative;
  font-size: 18px;
  margin: 0 auto;
  padding-top: 40px;
  height: 35px;
  text-shadow: 0 0 1px white, 0 1px 2px rgba(0, 0, 0, 0.3);
}

.ih-item.circle.effect .info p {
  color: #bbb;
  padding: 0px 0px 0px 0px;
  font-style: italic;
  padding-left: 0px;
  font-size: 10px;
}

.ih-item.circle.effect.bottom_to_top .img {
  -webkit-transform-origin: 50% 0;
  -moz-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  -o-transform-origin: 50% 0;
  transform-origin: 50% 0;
}

.ih-item.circle.effect.bottom_to_top a:hover .img {
  -webkit-transform: rotate3d(1, 0, 0, 180deg);
  -moz-transform: rotate3d(1, 0, 0, 180deg);
  -ms-transform: rotate3d(1, 0, 0, 180deg);
  -o-transform: rotate3d(1, 0, 0, 180deg);
  transform: rotate3d(1, 0, 0, 180deg);
}

.ih-item.circle.effect.top_to_bottom .img {
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.ih-item.circle.effect.top_to_bottom a:hover .img {
  -webkit-transform: rotate3d(1, 0, 0, -180deg);
  -moz-transform: rotate3d(1, 0, 0, -180deg);
  -ms-transform: rotate3d(1, 0, 0, -180deg);
  -o-transform: rotate3d(1, 0, 0, -180deg);
  transform: rotate3d(1, 0, 0, -180deg);
}

.ih-item.circle.effect.left_to_right .img {
  -webkit-transform-origin: 100% 50%;
  -moz-transform-origin: 100% 50%;
  -ms-transform-origin: 100% 50%;
  -o-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.ih-item.circle.effect.left_to_right a:hover .img {
  -webkit-transform: rotate3d(0, 1, 0, 180deg);
  -moz-transform: rotate3d(0, 1, 0, 180deg);
  -ms-transform: rotate3d(0, 1, 0, 180deg);
  -o-transform: rotate3d(0, 1, 0, 180deg);
  transform: rotate3d(0, 1, 0, 180deg);
}

.ih-item.circle.effect.right_to_left .img {
  -webkit-transform-origin: 0% 50%;
  -moz-transform-origin: 0% 50%;
  -ms-transform-origin: 0% 50%;
  -o-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
}

.ih-item.circle.effect.right_to_left a:hover .img {
  -webkit-transform: rotate3d(0, 1, 0, -180deg);
  -moz-transform: rotate3d(0, 1, 0, -180deg);
  -ms-transform: rotate3d(0, 1, 0, -180deg);
  -o-transform: rotate3d(0, 1, 0, -180deg);
  transform: rotate3d(0, 1, 0, -180deg);
}

.ih-item a {
  color: #333;
}

.ih-item a:hover {
  text-decoration: none;
}

.ih-item img {
  width: 100%;
  height: 100%;
}

.ih-item.circle {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.ih-item.circle .img {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.ih-item.circle .img:before {
  position: absolute;
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.ih-item.circle .img img {
  border-radius: 50%;
}

.ih-item.circle .info {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
@media all and (max-width: 780px) {
  .ih-item.circle .img {
      position: relative;
      width: 100px;
      height: 100px;
      /*margin-top: 20px;*/
      border-radius: 50%;
  }
  .ih-item.circle {
      position: relative;
      width: 100px;
      height: 100px;
      border-radius: 50%;
  }
  .ih-item.circle .info .info-back h2{
    font-size: 0.9em;
  }
}
/**************抖动效果******************/
.profilepic {
  text-align: center;
  display: block;
  -webkit-box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0px 2px 20px 3px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0px 2px 20px 3px rgba(0, 0, 0, 0.25);
  border-radius: 300px;
  width: 128px;
  height: 128px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  background: #88acdb;
  -webkit-transition: all 0.2s ease-in;
  display: -webkit-box;
  -webkit-box-orient: horizontal;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  text-align: center;
}

/* .profilepic img.show {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.profilepic img {
  width: 20%;
  height: 20%;
  border-radius: 300px;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in;
} 

.profilepic:hover {
  -webkit-animation: btnGroups 1s linear;
  -webkit-animation: btnGroups 1s linear;
  -moz-animation: btnGroups 1s linear;
  -ms-animation: btnGroups 1s linear;
  animation: btnGroups 1s linear;
} */

/*头像效果-end*/

.animated {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}

.animated.hinge {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
}

@-webkit-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-moz-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-o-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes shake {

  0%,
  100% {
    -webkit-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translateX(10px);
  }
}

@-moz-keyframes shake {

  0%,
  100% {
    -moz-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -moz-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -moz-transform: translateX(10px);
  }
}

@-o-keyframes shake {

  0%,
  100% {
    -o-transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -o-transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    -o-transform: translateX(10px);
  }
}

.shake {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-30px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-30px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }

  10%,
  20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -moz-transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }

  10%,
  20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -o-transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }

  100% {
    -o-transform: scale(1) rotate(0);
  }
}

.tada {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes swing {

  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transform-origin: top center;
  }

  20% {
    -webkit-transform: rotate(15deg);
  }

  40% {
    -webkit-transform: rotate(-10deg);
  }

  60% {
    -webkit-transform: rotate(5deg);
  }

  80% {
    -webkit-transform: rotate(-5deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(15deg);
  }

  40% {
    -moz-transform: rotate(-10deg);
  }

  60% {
    -moz-transform: rotate(5deg);
  }

  80% {
    -moz-transform: rotate(-5deg);
  }

  100% {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20% {
    -o-transform: rotate(15deg);
  }

  40% {
    -o-transform: rotate(-10deg);
  }

  60% {
    -o-transform: rotate(5deg);
  }

  80% {
    -o-transform: rotate(-5deg);
  }

  100% {
    -o-transform: rotate(0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }

  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }

  15% {
    -moz-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -moz-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -moz-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }

  15% {
    -o-transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    -o-transform: translateX(20%) rotate(3deg);
  }

  45% {
    -o-transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    -o-transform: translateX(10%) rotate(2deg);
  }

  75% {
    -o-transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    -o-transform: translateX(0%);
  }
}

.wobble {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.1);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }

  50% {
    -moz-transform: scale(1.1);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }

  50% {
    -o-transform: scale(1.1);
  }

  100% {
    -o-transform: scale(1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -webkit-animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
  }

  100% {
    -webkit-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}

@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -moz-animation-timing-function: ease-out;
  }

  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -moz-animation-timing-function: ease-out;
  }

  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }

  80% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -moz-animation-timing-function: ease-in;
  }

  100% {
    -moz-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    -o-animation-timing-function: ease-out;
  }

  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    -o-animation-timing-function: ease-out;
  }

  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }

  80% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    -o-animation-timing-function: ease-in;
  }

  100% {
    -o-transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipInX {
  0% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -moz-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -moz-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInX {
  0% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    -o-transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    -o-transform: perspective(400px) rotateX(10deg);
  }

  100% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInX;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInX;
  backface-visibility: visible !important;
  animation-name: flipInX;
}

@-webkit-keyframes flipOutX {
  0% {
    -webkit-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-moz-keyframes flipOutX {
  0% {
    -moz-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutX {
  0% {
    -o-transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  -moz-animation-name: flipOutX;
  -moz-backface-visibility: visible !important;
  -o-animation-name: flipOutX;
  -o-backface-visibility: visible !important;
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipInY {
  0% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipInY {
  0% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -moz-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -moz-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInY {
  0% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    -o-transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    -o-transform: perspective(400px) rotateY(10deg);
  }

  100% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipInY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipInY;
  backface-visibility: visible !important;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutY {
  0% {
    -webkit-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-moz-keyframes flipOutY {
  0% {
    -moz-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutY {
  0% {
    -o-transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flipOutY;
  -o-backface-visibility: visible !important;
  -o-animation-name: flipOutY;
  backface-visibility: visible !important;
  animation-name: flipOutY;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  -moz-animation-name: fadeOut;
  -o-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  -moz-animation-name: fadeOutDown;
  -o-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  -moz-animation-name: fadeOutLeft;
  -o-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  -moz-animation-name: fadeOutRight;
  -o-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  -moz-animation-name: fadeOutUpBig;
  -o-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  -moz-animation-name: fadeOutDownBig;
  -o-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  -moz-animation-name: fadeOutLeftBig;
  -o-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  -moz-animation-name: fadeOutRightBig;
  -o-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  -moz-animation-name: slideInDown;
  -o-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  -moz-animation-name: slideInLeft;
  -o-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  -moz-animation-name: slideInRight;
  -o-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes slideOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes slideOutUp {
  0% {
    -o-transform: translateY(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  -moz-animation-name: slideOutUp;
  -o-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes slideOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes slideOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  -moz-animation-name: slideOutLeft;
  -o-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes slideOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes slideOutRight {
  0% {
    -o-transform: translateX(0);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  -moz-animation-name: slideOutRight;
  -o-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }

  70% {
    -webkit-transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }

  70% {
    -moz-transform: scale(0.9);
  }

  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }

  70% {
    -o-transform: scale(0.9);
  }

  100% {
    -o-transform: scale(1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }

  80% {
    -webkit-transform: translateY(10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }

  80% {
    -moz-transform: translateY(10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }

  80% {
    -o-transform: translateY(10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }

  80% {
    -webkit-transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }

  80% {
    -moz-transform: translateY(-10px);
  }

  100% {
    -moz-transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }

  80% {
    -o-transform: translateY(-10px);
  }

  100% {
    -o-transform: translateY(0);
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }

  80% {
    -webkit-transform: translateX(-10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }

  80% {
    -moz-transform: translateX(-10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }

  80% {
    -o-transform: translateX(-10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }

  80% {
    -webkit-transform: translateX(10px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }

  80% {
    -moz-transform: translateX(10px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }

  80% {
    -o-transform: translateX(10px);
  }

  100% {
    -o-transform: translateX(0);
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
  }

  25% {
    -webkit-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
}

@-moz-keyframes bounceOut {
  0% {
    -moz-transform: scale(1);
  }

  25% {
    -moz-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -moz-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    -o-transform: scale(1);
  }

  25% {
    -o-transform: scale(0.95);
  }

  50% {
    opacity: 1;
    -o-transform: scale(1.1);
  }

  100% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  -moz-animation-name: bounceOut;
  -o-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  -moz-animation-name: bounceOutUp;
  -o-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    -moz-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    -o-transform: translateY(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -o-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  -moz-animation-name: bounceOutLeft;
  -o-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
    -moz-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
    -o-transform: translateX(0);
  }

  20% {
    opacity: 1;
    -o-transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  -moz-animation-name: bounceOutRight;
  -o-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes rotateIn {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateIn {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateIn {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  -moz-animation-name: rotateIn;
  -o-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  -moz-animation-name: rotateInUpLeft;
  -o-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  -moz-animation-name: rotateInDownLeft;
  -o-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  -moz-animation-name: rotateInUpRight;
  -o-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateInDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  -moz-animation-name: rotateInDownRight;
  -o-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateOut {
  0% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: rotate(200deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOut {
  0% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: center center;
    -moz-transform: rotate(200deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOut {
  0% {
    -o-transform-origin: center center;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: center center;
    -o-transform: rotate(200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  -moz-animation-name: rotateOut;
  -o-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  -moz-animation-name: rotateOutUpLeft;
  -o-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: left bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: left bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: left bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  -moz-animation-name: rotateOutDownLeft;
  -o-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  -moz-animation-name: rotateOutUpRight;
  -o-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -webkit-transform-origin: right bottom;
    -webkit-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -moz-transform-origin: right bottom;
    -moz-transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(0);
    opacity: 1;
  }

  100% {
    -o-transform-origin: right bottom;
    -o-transform: rotate(-90deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  -moz-animation-name: rotateOutDownRight;
  -o-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -moz-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -moz-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-o-keyframes lightSpeedIn {
  0% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -o-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    -o-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -o-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  -moz-animation-timing-function: ease-out;
  -o-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
    -moz-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -moz-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-o-keyframes lightSpeedOut {
  0% {
    -o-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    -o-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  -moz-animation-name: lightSpeedOut;
  -o-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform: rotate(0);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate(80deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  40% {
    -webkit-transform: rotate(60deg);
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  80% {
    -webkit-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -webkit-transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
  }

  100% {
    -webkit-transform: translateY(700px);
    opacity: 0;
  }
}

@-moz-keyframes hinge {
  0% {
    -moz-transform: rotate(0);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -moz-transform: rotate(80deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  40% {
    -moz-transform: rotate(60deg);
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  80% {
    -moz-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -moz-transform-origin: top left;
    -moz-animation-timing-function: ease-in-out;
  }

  100% {
    -moz-transform: translateY(700px);
    opacity: 0;
  }
}

@-o-keyframes hinge {
  0% {
    -o-transform: rotate(0);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -o-transform: rotate(80deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  40% {
    -o-transform: rotate(60deg);
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  80% {
    -o-transform: rotate(60deg) translateY(0);
    opacity: 1;
    -o-transform-origin: top left;
    -o-animation-timing-function: ease-in-out;
  }

  100% {
    -o-transform: translateY(700px);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  -moz-animation-name: hinge;
  -o-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }
}

@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }
}

@-o-keyframes rollIn {
  0% {
    opacity: 0;
    -o-transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  -moz-animation-name: rollIn;
  -o-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
  }
}

@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    -moz-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -moz-transform: translateX(100%) rotate(120deg);
  }
}

@-o-keyframes rollOut {
  0% {
    opacity: 1;
    -o-transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    -o-transform: translateX(100%) rotate(120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  -moz-animation-name: rollOut;
  -o-animation-name: rollOut;
  animation-name: rollOut;
}

@-moz-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-webkit-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-o-keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@-moz-keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@-webkit-keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@-o-keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@keyframes shake {

  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-10px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(10px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}

@-moz-keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@-webkit-keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(3deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-3deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

@-moz-keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-moz-keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-25%) rotate(-5deg);
  }

  30% {
    transform: translateX(20%) rotate(3deg);
  }

  45% {
    transform: translateX(-15%) rotate(-3deg);
  }

  60% {
    transform: translateX(10%) rotate(2deg);
  }

  75% {
    transform: translateX(-5%) rotate(-1deg);
  }

  100% {
    transform: translateX(0%);
  }
}

@-moz-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@-moz-keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}

@-webkit-keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) translateZ(0) rotateY(0) scale(1);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg) scale(1);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(0.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) translateZ(0) rotateY(360deg) scale(1);
    animation-timing-function: ease-in;
  }
}

@-moz-keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@keyframes flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateX(-10deg);
  }

  70% {
    transform: perspective(400px) rotateX(10deg);
  }

  100% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  0% {
    transform: perspective(400px) rotateX(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}

@-moz-keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-o-keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@keyframes flipInY {
  0% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotateY(-10deg);
  }

  70% {
    transform: perspective(400px) rotateY(10deg);
  }

  100% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }
}

@-moz-keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-o-keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  0% {
    transform: perspective(400px) rotateY(0deg);
    opacity: 1;
  }

  100% {
    transform: perspective(400px) rotateY(90deg);
    opacity: 0;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@-moz-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@-o-keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

@-moz-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@-o-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-20px);
  }
}

@-moz-keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

@-o-keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(20px);
  }
}

@-moz-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-o-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-moz-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-o-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-moz-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-o-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-moz-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-o-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-moz-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

@-o-keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-o-keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@keyframes slideOutUp {
  0% {
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-moz-keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-o-keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-moz-keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-webkit-keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-o-keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@keyframes slideOutRight {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }

  50% {
    opacity: 1;
    transform: scale(1.05);
  }

  70% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(-30px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateY(30px);
  }

  80% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(30px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }

  60% {
    opacity: 1;
    transform: translateX(-30px);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

@-webkit-keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

@-o-keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

@keyframes bounceOut {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(0.95);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  100% {
    opacity: 0;
    transform: scale(0.3);
  }
}

@-moz-keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-webkit-keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-o-keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@keyframes bounceOutUp {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(20px);
  }

  100% {
    opacity: 0;
    transform: translateY(-2000px);
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-webkit-keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-o-keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0);
  }

  20% {
    opacity: 1;
    transform: translateY(-20px);
  }

  100% {
    opacity: 0;
    transform: translateY(2000px);
  }
}

@-moz-keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-webkit-keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-o-keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@keyframes bounceOutLeft {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(20px);
  }

  100% {
    opacity: 0;
    transform: translateX(-2000px);
  }
}

@-moz-keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-webkit-keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-o-keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@keyframes bounceOutRight {
  0% {
    transform: translateX(0);
  }

  20% {
    opacity: 1;
    transform: translateX(-20px);
  }

  100% {
    opacity: 0;
    transform: translateX(2000px);
  }
}

@-moz-keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateIn {
  0% {
    transform-origin: center center;
    transform: rotate(-200deg);
    opacity: 0;
  }

  100% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-webkit-keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-o-keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }
}

@-moz-keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  0% {
    transform-origin: center center;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: center center;
    transform: rotate(200deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    -transform-origin: left bottom;
    -transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  0% {
    transform-origin: left bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: left bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(90deg);
    opacity: 0;
  }
}

@-moz-keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-webkit-keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-o-keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  0% {
    transform-origin: right bottom;
    transform: rotate(0);
    opacity: 1;
  }

  100% {
    transform-origin: right bottom;
    transform: rotate(-90deg);
    opacity: 0;
  }
}

@-moz-keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-o-keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }

  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }

  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }

  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}

@-moz-keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-webkit-keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-o-keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }

  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}

@-moz-keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

@-webkit-keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

@-o-keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    transform: rotate(0);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    transform: rotate(80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  40% {
    transform: rotate(60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  80% {
    transform: rotate(60deg) translateY(0);
    opacity: 1;
    transform-origin: top left;
    animation-timing-function: ease-in-out;
  }

  100% {
    transform: translateY(700px);
    opacity: 0;
  }
}

@-moz-keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@-o-keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@keyframes rollIn {
  0% {
    opacity: 0;
    transform: translateX(-100%) rotate(-120deg);
  }

  100% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}

@-moz-keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

@-o-keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

@keyframes rollOut {
  0% {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }

  100% {
    opacity: 0;
    transform: translateX(100%) rotate(120deg);
  }
}

.article-entry pre,
.article-entry .highlight {
  background: #2d2d2d;
  /*margin: 0 article-padding * -1*/
  padding: 15px 20px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 0;
  overflow: auto;
  color: #ccc;
  line-height: 22.400000000000002px;
}

.article-entry .highlight .gutter pre,
.article-entry .gist .gist-file .gist-data .line-numbers {
  color: #666;
  font-size: 0.85em;
}

.article-entry pre,
.article-entry code {
  font-family: "Source Code Pro", Consolas, Monaco, Menlo, Consolas, monospace;
}

.article-entry code {
  background: #eee;
  text-shadow: 0 1px #fff;
  padding: 0 0.3em;
}

.article-entry pre code {
  background: none;
  text-shadow: none;
  padding: 0;
}

.article-entry .highlight pre {
  border: none;
  margin: 10;
  padding: 0;
}

.article-entry .highlight table {
  margin: 0;
  width: auto;
}

.article-entry .highlight td {
  border: none;
  padding: 0;
}

.article-entry .highlight figcaption {
  font-size: 0.85em;
  color: #999;
  line-height: 1em;
  margin-bottom: 1em;
}

.article-entry .highlight figcaption a {
  float: right;
}

.article-entry .highlight .gutter pre {
  text-align: right;
  padding-right: 20px;
}

.article-entry .highlight .line {
  height: 22.400000000000002px;
}

.article-entry .gist {
  margin: 0 -20px;
  border-style: solid;
  border-color: #ddd;
  border-width: 1px 0;
  background: #2d2d2d;
  padding: 15px 20px 15px 0;
}

.article-entry .gist .gist-file {
  border: none;
  font-family: "Source Code Pro", Consolas, Monaco, Menlo, Consolas, monospace;
  margin: 0;
}

.article-entry .gist .gist-file .gist-data {
  background: none;
  border: none;
}

.article-entry .gist .gist-file .gist-data .line-numbers {
  background: none;
  border: none;
  padding: 0 20px 0 0;
}

.article-entry .gist .gist-file .gist-data .line-data {
  padding: 0 !important;
}

.article-entry .gist .gist-file .highlight {
  margin: 0;
  padding: 0;
  border: none;
}

.article-entry .gist .gist-file .gist-meta {
  background: #2d2d2d;
  color: #999;
  font: 0.85em "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-shadow: 0 0;
  padding: 0;
  margin-top: 1em;
  margin-left: 20px;
}

.article-entry .gist .gist-file .gist-meta a {
  color: #258fb8;
  font-weight: normal;
}

.article-entry .gist .gist-file .gist-meta a:hover {
  text-decoration: underline;
}

pre .comment,
pre .title {
  color: #999;
}

pre .variable,
pre .attribute,
pre .tag,
pre .regexp,
pre .ruby .constant,
pre .xml .tag .title,
pre .xml .pi,
pre .xml .doctype,
pre .html .doctype,
pre .css .id,
pre .css .class,
pre .css .pseudo {
  color: #f2777a;
}

pre .number,
pre .preprocessor,
pre .built_in,
pre .literal,
pre .params,
pre .constant {
  color: #f99157;
}

pre .class,
pre .ruby .class .title,
pre .css .rules .attribute {
  color: #9c9;
}

pre .string,
pre .value,
pre .inheritance,
pre .header,
pre .ruby .symbol,
pre .xml .cdata {
  color: #9c9;
}

pre .css .hexcolor {
  color: #6cc;
}

pre .function,
pre .python .decorator,
pre .python .title,
pre .ruby .function .title,
pre .ruby .title .keyword,
pre .perl .sub,
pre .javascript .title,
pre .coffeescript .title {
  color: #69c;
}

pre .keyword,
pre .javascript .function {
  color: #c9c;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after {
  content: '';
  content: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

figure table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  text-align: left;
  border-width: 0px;
  margin: auto;
}

figure th,
figure td {
  padding: 0px;
}

body {
  width: 100%;
  *zoom: 1;
}

body:before,
body:after {
  content: "";
  display: table;
}

body:after {
  clear: both;
}

html,
body {
  height: 100%;
}

html {
  height: 100%;
  max-height: 100%;
}

body {
  font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size: 1em;
  color: #666;
  -webkit-font-smoothing: antialiased;
}

::selection {
  background: rgba(37, 104, 163, 0.2);
}

::-moz-selection {
  background: rgba(37, 104, 163, 0.2);
}

a {
  text-decoration: none;
  color: #4e97d8;
}

a:hover {
  color: #4e97d8;
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-weight: lighter;
  color: #333;
  -webkit-font-smoothing: antialiased;
}

h1 {
  margin-top: 0;
  font-size: 2.5em;
  line-height: 1.2em;
  letter-spacing: 0.05em;
}

h2 {
  font-size: 2em;
}

h3 {
  font-size: 1.6em;
}

h4 {
  font-size: 1.2em;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 1em;
}

p {
  margin-bottom: 1.3em;
  line-height: 1.7em;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-weight: lighter;
  font-size: 1em;
  border-left: 3px solid #4e97d8;
}

blockquote p:last-child {
  margin-bottom: 0;
}

ol,
ul {
  margin: 0 0 1.3em 2.5em;
}

ol li,
ul li {
  margin: 0 0 0.2em 0;
  line-height: 1.6em;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0.1em 0 0.2em 2em;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

code {
  padding: 0.1em 0.4em;
  background: #e8f2fb;
  border: 1px solid #c9e1f6;
  border-radius: 3px;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.9em;
  vertical-align: bottom;
  word-wrap: break-word;
}

pre {
  margin-bottom: 1.3em;
  padding: 1em 2.5%;
  background: #e8f2fb;
  border: 1px solid #c9e1f6;
  border-radius: 3px;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.7em;
  overflow: scroll;
}

pre code {
  padding: 0;
  background: none;
  border: none;
  word-wrap: normal;
}

table {
  color: #333;
  font-size: 0.9em;
  text-align: center;
  line-height: 40px;
  border-spacing: 0;
  border: 2px solid #4e97d8;
  width: 90%;
  margin: 50px auto;
}

thead tr:first-child {
  background-color: #4e97d8;
  color: #f8f8f8;
  border: none;
}

th {
  font-weight: bold;
}

th,
td {
  padding: 0 8px 0 8px;
}

thead tr:last-child th {
  border-bottom: 1px solid #ddd;
}

tbody tr:last-child td {
  border: none;
}

tbody td {
  border-bottom: 1px solid #ddd;
  font-size: 0.9em;
}

.date,
.time,
.author,
.tags {
  font-size: 0.8em;
  color: #c7c7c7;
}

.date a,
.time a,
.author a,
.tags a {
  color: #666;
}

.date a:hover,
.time a:hover,
.author a:hover,
.tags a:hover {
  color: #4e97d8;
}

.excerpt {
  margin: 0;
  font-size: 0.9em;
  color: #999;
}

.intro {
  font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size: 1.2em;
  font-weight: lighter;
  color: #999;
}

.block-heading {
  display: inline;
  float: left;
  width: 940px;
  margin: 0 10px;
  position: relative;
  bottom: -15px;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.label {
  position: relative;
  display: inline-block;
  padding: 8px 18px 9px 18px;
  background: #4e97d8;
  border-radius: 3px;
  text-align: center;
  color: #fff;
}

.container {
  position: relative;
  z-index: 500;
  width: 940px;
  margin: 0 auto;
}

.content-wrapper {
  z-index: 800;
  width: 70%;
  margin-left: 30%;
}

.content-wrapper__inner {
  margin: 0 10%;
  padding: 50px 0;
}

.footer {
  display: block;
  padding: 2em 0 0 0;
  border-top: 2px solid #ddd;
  font-size: 0.7em;
  color: #b3b3b3;
}

.footer__copyright {
  display: block;
  margin-bottom: 0.7em;
}

.footer__copyright a {
  color: #a6a6a6;
  text-decoration: underline;
}

.footer__copyright a:hover {
  color: #4e97d8;
}

.avatar,

.logo {
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.3);
}

hr {
  border: none;
}

.section-title__divider {
  width: 30%;
  margin: 2.2em 0 2.1em 0;
  border-top: 1px solid #ddd;
}

.hidden {
  display: none !important;
}

.panel {
  display: table;
  width: 100%;
  height: 100%;
}

.panel__vertical {
  display: table-cell;
  vertical-align: middle;
}

.panel-title {
  font-family: 'Old English Text MT', 'EngraversOldEnglishBT-Bold', 'Lato', "PingFang SC", "Microsoft YaHei", sans-serif;
  margin: 0 0 5px 0;
  font-size: 2.5em;
  /*letter-spacing: 4px;*/
  color: #fff;
}

.panel-subtitle {
  font-family: "ff-tisa-web-pro-1", "ff-tisa-web-pro-2", "Lucida Grande", "Hiragino Sans GB", "Hiragino Sans GB W3", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  font-size: 1.2em;
  font-weight: lighter;
  letter-spacing: 3px;
  color: #ccc;
  -webkit-font-smoothing: antialiased;
}

.iUp {
  opacity: 0;
  -webkit-transform: translate3d(0, 80px, 0);
  -moz-transform: translate3d(0, 80px, 0);
  -ms-transform: translate3d(0, 80px, 0);
  -o-transform: translate3d(0, 80px, 0);
  transform: translate3d(0, 80px, 0);
  -webkit-transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 2s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 2s cubic-bezier(0.19, 1, 0.22, 1)
}

.iUp.up {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.panel-cover {
  display: block;
  position: fixed;
  z-index: 900;
  width: 100%;
  max-width: none;
  height: 100%;
  background: center center no-repeat #666;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  transition: width 0.6s ease
}

.panel-cover--collapsed {
  width: 30%
}

.panel-cover--collapsed .iUp {
  -webkit-transition: all 0 cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: all 0 cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0 cubic-bezier(0.19, 1, 0.22, 1)
}

.panel-cover--overlay {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  z-index: 1;
  -moz-opacity: .55;
  opacity: .55;
}

.panel-cover__logo {
  margin-bottom: 0.2em;

}

.panel-cover__description {
  margin: 0 30px;
}

.panel-cover__divider {
  width: 50%;
  margin: 20px auto;
  border-top: 1px solid rgba(255, 255, 255, 0.14);
}

.panel-cover__divider--secondary {
  width: 15%;
}

.panel-main {
  display: table;
  width: 100%;
  height: 100%;
}

.panel-main__inner {
  display: table-cell;
  vertical-align: middle;
  position: relative;
  z-index: 800;
  padding: 0 60px;
}

.panel-main__content {
  max-width: 620px;
  margin: 0 auto;
}

.panel-main__content--fixed {
  width: 480px;
  transition: width 1s;
  -webkit-transition: width 1s;
  /* Safari */
}

.panel-inverted {
  font-weight: 100;
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

.panel-inverted a {
  color: #fff;
}

.cover-navigation {
  margin-top: 42px;
}

.cover-navigation--social {
  margin-left: 30px;
}

.btn,
.navigation__item a {
  padding: 10px 20px;
  border: 1px solid #4e97d8;
  border-radius: 20px;
  font-size: 0.9em;
  font-weight: bold;
  letter-spacing: 1px;
  text-shadow: none;
  color: #4e97d8;
  -webkit-font-smoothing: antialiased;
}

.btn:hover,
.navigation__item a:hover {
  color: #4e97d8;
  border-color: #4e97d8;
}

.btn-border-small {
  border: 1px solid #4e97d8;
  border-radius: 20px;
  padding: 6px 8px;
  font-size: 0.8em;
  margin-left: 10px;
}

.btn-secondary {
  border-color: #5ba4e5;
  color: #5ba4e5;
}

.btn-secondary:hover {
  color: #217fd2;
  border-color: #217fd2;
}

.btn-tertiary {
  border-color: #999;
  color: #999;
}

.btn-tertiary:hover {
  color: #737373;
  border-color: #737373;
}

.btn-large {
  padding: 10px 24px;
  font-size: 1.1em;
}

.btn-small {
  padding: 8px 12px;
  font-size: 0.7em;
}

.btn-mobile-menu {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: rgba(51, 51, 51, 0.98);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: center;
}

.btn-mobile-menu__icon,
.btn-mobile-close__icon {
  position: relative;
  top: 10px;
  color: #fff;
}

nav {
  display: inline-block;
  position: relative;
}

.navigation {
  display: inline-block;
  float: left;
  position: relative;
  margin: 0;
  list-style-type: none;
}

.navigation__item {
  display: inline-block;
  margin: 5px 1px 0 0;
  line-height: 1em;
}

.navigation__item a {
  display: block;
  position: relative;
  border-color: #fff;
  color: #fff;
  opacity: 0.8;
}

.navigation__item a:hover {
  color: #fff;
  border-color: #fff;
  opacity: 1;
}

.navigation--social a {
  border: 0px;
  padding: 6px 8px 6px 9px;
}

.navigation--social a .label {
  display: none;
}

.navigation--social a .icon {
  display: block;
  font-size: 1.7em;
}

.pagination {
  display: block;
  margin: 0 0 4em 0;
}

.pagination__page-number {
  margin: 0;
  font-size: 0.8em;
  color: #999;
}

.pagination__newer {
  margin-right: 1em;
}

.pagination__older {
  margin-left: 1em;
}

i {
  font-family: 'entypo';
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
}

.social {
  font-size: 22px;
}

.icon-social {
  font-family: 'entypo-social';
  font-size: 22px;
  display: block;
  position: relative;
}

*:focus {
  outline: none;
}

@media all and (max-width: 1100px) {
  .panel-cover__logo {
    width: 70px;
  }

  .panel-title {
    font-size: 2em;
  }

  .panel-subtitle {
    font-size: 1em;
  }

  .panel-cover__description {
    margin: 0 10px;
    font-size: 0.9em;
  }

  .navigation--social {
    margin-top: 5px;
    margin-left: 0;
  }
}

@media all and (max-width: 960px) {
  .btn-mobile-menu {
    display: block;
  }

  .panel-main {
    display: table;
    position: relative;
  }

  .panel-cover--collapsed {
    width: 100%;
    max-width: none;
  }

  .panel-main__inner {
    display: table-cell;
    padding: 60px 10%;
  }

  .panel-cover__description {
    display: block;
    max-width: 600px;
    margin: 0 auto;
  }

  .panel-cover__divider--secondary {
    display: none;
  }

  .panel-cover {
    width: 100%;
    height: 100%;
    background-position: center center;
  }

  .panel-cover.panel-cover--collapsed {
    display: block;
    position: relative;
    height: auto;
    padding: 0;
    background-position: center center;
  }

  .panel-cover.panel-cover--collapsed .panel-main__inner {
    display: block;
    padding: 70px 0 30px 0;
  }

  .panel-cover.panel-cover--collapsed .panel-cover__logo {
    width: 60px;
    border-width: 2px;
  }

  .panel-cover.panel-cover--collapsed .panel-cover__description {
    display: none;
  }

  .panel-cover.panel-cover--collapsed .panel-cover__divider {
    display: none;
    margin: 1em auto;
  }

  .navigation-wrapper {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    background: rgba(51, 51, 51, 0.98);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  .navigation-wrapper.visible {
    display: block;
  }

  .cover-navigation {
    display: block;
    position: relative;
    float: left;
    clear: left;
    width: 100%;
  }

  .cover-navigation .navigation {
    display: block;
    width: 100%;
  }

  .cover-navigation .navigation li {
    width: 80%;
    margin-bottom: 0.4em;
  }

  .cover-navigation.navigation--social {
    padding-top: 5px;
  }

  .cover-navigation.navigation--social .navigation li {
    display: inline-block;
    width: 20%;
  }

  .content-wrapper {
    width: 80%;
    max-width: none;
    margin: 0 auto;
  }

  .content-wrapper__inner {
    margin-right: 0;
    margin-left: 0;
  }

  .navigation__item {
    width: 100%;
    margin: 0 0 0.4em 0;
  }
}

@media all and (max-width: 340px) {
  .panel-main__inner {
    padding: 0 5%;
  }

  .panel-title {
    margin-bottom: 0.1em;
    font-size: 1.5em;
  }

  .panel-subtitle {
    font-size: 0.9em;
  }

  .btn,
  .navigation__item a {
    display: block;
    margin-bottom: 0.4em;
  }
}

/***************************************************************************************/
.remark {
  position: absolute;
  z-index: 900;
  bottom: 0;
  right: 5px;
  text-align: center;
  color: #fff;
  opacity: .60 !important;
  padding-bottom: 3px;
  padding-right: 18px;
  letter-spacing: -3px;
  font-size: 0;
}

@media screen and (max-width: 960px) {
  .remark {
    display: none;
  }
}

.power {
  display: inline-block;
  color: #fff !important;
  letter-spacing: 0;
  font-size: 10px;
  line-height: 16px;
  margin: 0;
  vertical-align: middle;
}

.beian {
  position: absolute;
  z-index: 900;
  bottom: 0;
  right: 5px;
  text-align: center;
  color: #fff;
  opacity: .25 !important;
  padding-bottom: 3px;
  letter-spacing: -3px;
  font-size: 0;
}

.gwab,
.icp {
  display: inline-block;
  color: #fff !important;
  letter-spacing: 0;
  font-size: 10px;
  line-height: 16px;
  margin: 0;
  vertical-align: middle;
}

.gwab {
  background: url(/img/beian.png) no-repeat;
  background-size: 12px 12px;
  background-position: 3px 2px;
  padding-left: 18px;
}

@-webkit-keyframes btnGroups {
  0% {
    -webkit-transform: scale(1.2, 0.8);
  }

  1% {
    -webkit-transform: scale(1.18, 0.82);
  }

  2% {
    -webkit-transform: scale(1.16, 0.84);
  }

  3% {
    -webkit-transform: scale(1.13, 0.87);
  }

  4% {
    -webkit-transform: scale(1.1, 0.9);
  }

  5% {
    -webkit-transform: scale(1.07, 0.93);
  }

  6% {
    -webkit-transform: scale(1.04, 0.96);
  }

  7% {
    -webkit-transform: scale(1.01, 0.99);
  }

  8% {
    -webkit-transform: scale(0.99, 1.01);
  }

  9% {
    -webkit-transform: scale(0.97, 1.03);
  }

  10% {
    -webkit-transform: scale(0.95, 1.05);
  }

  11% {
    -webkit-transform: scale(0.94, 1.06);
  }

  12% {
    -webkit-transform: scale(0.93, 1.07);
  }

  13% {
    -webkit-transform: scale(0.93, 1.07);
  }

  14% {
    -webkit-transform: scale(0.93, 1.07);
  }

  15% {
    -webkit-transform: scale(0.93, 1.07);
  }

  16% {
    -webkit-transform: scale(0.94, 1.06);
  }

  17% {
    -webkit-transform: scale(0.94, 1.06);
  }

  18% {
    -webkit-transform: scale(0.95, 1.05);
  }

  19% {
    -webkit-transform: scale(0.96, 1.04);
  }

  20% {
    -webkit-transform: scale(0.98, 1.02);
  }

  21% {
    -webkit-transform: scale(0.99, 1.01);
  }

  22% {
    -webkit-transform: scale(1, 1);
  }

  23% {
    -webkit-transform: scale(1, 1);
  }

  24% {
    -webkit-transform: scale(1.01, 0.99);
  }

  25% {
    -webkit-transform: scale(1.02, 0.98);
  }

  26% {
    -webkit-transform: scale(1.02, 0.98);
  }

  27% {
    -webkit-transform: scale(1.02, 0.98);
  }

  28% {
    -webkit-transform: scale(1.03, 0.97);
  }

  29% {
    -webkit-transform: scale(1.03, 0.97);
  }

  30% {
    -webkit-transform: scale(1.02, 0.98);
  }

  31% {
    -webkit-transform: scale(1.02, 0.98);
  }

  32% {
    -webkit-transform: scale(1.02, 0.98);
  }

  33% {
    -webkit-transform: scale(1.02, 0.98);
  }

  34% {
    -webkit-transform: scale(1.01, 0.99);
  }

  35% {
    -webkit-transform: scale(1.01, 0.99);
  }

  36% {
    -webkit-transform: scale(1.01, 0.99);
  }

  37% {
    -webkit-transform: scale(1, 1);
  }

  38% {
    -webkit-transform: scale(1, 1);
  }

  39% {
    -webkit-transform: scale(1, 1);
  }

  40% {
    -webkit-transform: scale(0.99, 1.01);
  }

  41% {
    -webkit-transform: scale(0.99, 1.01);
  }

  42% {
    -webkit-transform: scale(0.99, 1.01);
  }

  43% {
    -webkit-transform: scale(0.99, 1.01);
  }

  44% {
    -webkit-transform: scale(0.99, 1.01);
  }

  45% {
    -webkit-transform: scale(0.99, 1.01);
  }

  46% {
    -webkit-transform: scale(0.99, 1.01);
  }

  47% {
    -webkit-transform: scale(0.99, 1.01);
  }

  48% {
    -webkit-transform: scale(0.99, 1.01);
  }

  49% {
    -webkit-transform: scale(1, 1);
  }
}

@-moz-keyframes btnGroups {
  0% {
    -webkit-transform: scale(1.2, 0.8);
    -moz-transform: scale(1.2, 0.8);
    -ms-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8);
  }

  1% {
    -webkit-transform: scale(1.18, 0.82);
    -moz-transform: scale(1.18, 0.82);
    -ms-transform: scale(1.18, 0.82);
    transform: scale(1.18, 0.82);
  }

  2% {
    -webkit-transform: scale(1.16, 0.84);
    -moz-transform: scale(1.16, 0.84);
    -ms-transform: scale(1.16, 0.84);
    transform: scale(1.16, 0.84);
  }

  3% {
    -webkit-transform: scale(1.13, 0.87);
    -moz-transform: scale(1.13, 0.87);
    -ms-transform: scale(1.13, 0.87);
    transform: scale(1.13, 0.87);
  }

  4% {
    -webkit-transform: scale(1.1, 0.9);
    -moz-transform: scale(1.1, 0.9);
    -ms-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  5% {
    -webkit-transform: scale(1.07, 0.93);
    -moz-transform: scale(1.07, 0.93);
    -ms-transform: scale(1.07, 0.93);
    transform: scale(1.07, 0.93);
  }

  6% {
    -webkit-transform: scale(1.04, 0.96);
    -moz-transform: scale(1.04, 0.96);
    -ms-transform: scale(1.04, 0.96);
    transform: scale(1.04, 0.96);
  }

  7% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  8% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  9% {
    -webkit-transform: scale(0.97, 1.03);
    -moz-transform: scale(0.97, 1.03);
    -ms-transform: scale(0.97, 1.03);
    transform: scale(0.97, 1.03);
  }

  10% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  11% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  12% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  13% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  14% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  15% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  16% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  17% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  18% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  19% {
    -webkit-transform: scale(0.96, 1.04);
    -moz-transform: scale(0.96, 1.04);
    -ms-transform: scale(0.96, 1.04);
    transform: scale(0.96, 1.04);
  }

  20% {
    -webkit-transform: scale(0.98, 1.02);
    -moz-transform: scale(0.98, 1.02);
    -ms-transform: scale(0.98, 1.02);
    transform: scale(0.98, 1.02);
  }

  21% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  22% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  23% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  24% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  25% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  26% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  27% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  28% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  29% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  30% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  31% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  32% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  33% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  34% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  35% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  36% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  37% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  38% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  39% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  40% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  41% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  42% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  43% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  44% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  45% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  46% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  47% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  48% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  49% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-webkit-keyframes btnGroups {
  0% {
    -webkit-transform: scale(1.2, 0.8);
    -moz-transform: scale(1.2, 0.8);
    -ms-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8);
  }

  1% {
    -webkit-transform: scale(1.18, 0.82);
    -moz-transform: scale(1.18, 0.82);
    -ms-transform: scale(1.18, 0.82);
    transform: scale(1.18, 0.82);
  }

  2% {
    -webkit-transform: scale(1.16, 0.84);
    -moz-transform: scale(1.16, 0.84);
    -ms-transform: scale(1.16, 0.84);
    transform: scale(1.16, 0.84);
  }

  3% {
    -webkit-transform: scale(1.13, 0.87);
    -moz-transform: scale(1.13, 0.87);
    -ms-transform: scale(1.13, 0.87);
    transform: scale(1.13, 0.87);
  }

  4% {
    -webkit-transform: scale(1.1, 0.9);
    -moz-transform: scale(1.1, 0.9);
    -ms-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  5% {
    -webkit-transform: scale(1.07, 0.93);
    -moz-transform: scale(1.07, 0.93);
    -ms-transform: scale(1.07, 0.93);
    transform: scale(1.07, 0.93);
  }

  6% {
    -webkit-transform: scale(1.04, 0.96);
    -moz-transform: scale(1.04, 0.96);
    -ms-transform: scale(1.04, 0.96);
    transform: scale(1.04, 0.96);
  }

  7% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  8% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  9% {
    -webkit-transform: scale(0.97, 1.03);
    -moz-transform: scale(0.97, 1.03);
    -ms-transform: scale(0.97, 1.03);
    transform: scale(0.97, 1.03);
  }

  10% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  11% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  12% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  13% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  14% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  15% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  16% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  17% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  18% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  19% {
    -webkit-transform: scale(0.96, 1.04);
    -moz-transform: scale(0.96, 1.04);
    -ms-transform: scale(0.96, 1.04);
    transform: scale(0.96, 1.04);
  }

  20% {
    -webkit-transform: scale(0.98, 1.02);
    -moz-transform: scale(0.98, 1.02);
    -ms-transform: scale(0.98, 1.02);
    transform: scale(0.98, 1.02);
  }

  21% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  22% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  23% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  24% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  25% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  26% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  27% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  28% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  29% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  30% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  31% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  32% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  33% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  34% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  35% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  36% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  37% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  38% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  39% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  40% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  41% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  42% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  43% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  44% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  45% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  46% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  47% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  48% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  49% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@-o-keyframes btnGroups {
  0% {
    -webkit-transform: scale(1.2, 0.8);
    -moz-transform: scale(1.2, 0.8);
    -ms-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8);
  }

  1% {
    -webkit-transform: scale(1.18, 0.82);
    -moz-transform: scale(1.18, 0.82);
    -ms-transform: scale(1.18, 0.82);
    transform: scale(1.18, 0.82);
  }

  2% {
    -webkit-transform: scale(1.16, 0.84);
    -moz-transform: scale(1.16, 0.84);
    -ms-transform: scale(1.16, 0.84);
    transform: scale(1.16, 0.84);
  }

  3% {
    -webkit-transform: scale(1.13, 0.87);
    -moz-transform: scale(1.13, 0.87);
    -ms-transform: scale(1.13, 0.87);
    transform: scale(1.13, 0.87);
  }

  4% {
    -webkit-transform: scale(1.1, 0.9);
    -moz-transform: scale(1.1, 0.9);
    -ms-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  5% {
    -webkit-transform: scale(1.07, 0.93);
    -moz-transform: scale(1.07, 0.93);
    -ms-transform: scale(1.07, 0.93);
    transform: scale(1.07, 0.93);
  }

  6% {
    -webkit-transform: scale(1.04, 0.96);
    -moz-transform: scale(1.04, 0.96);
    -ms-transform: scale(1.04, 0.96);
    transform: scale(1.04, 0.96);
  }

  7% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  8% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  9% {
    -webkit-transform: scale(0.97, 1.03);
    -moz-transform: scale(0.97, 1.03);
    -ms-transform: scale(0.97, 1.03);
    transform: scale(0.97, 1.03);
  }

  10% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  11% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  12% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  13% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  14% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  15% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  16% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  17% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  18% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  19% {
    -webkit-transform: scale(0.96, 1.04);
    -moz-transform: scale(0.96, 1.04);
    -ms-transform: scale(0.96, 1.04);
    transform: scale(0.96, 1.04);
  }

  20% {
    -webkit-transform: scale(0.98, 1.02);
    -moz-transform: scale(0.98, 1.02);
    -ms-transform: scale(0.98, 1.02);
    transform: scale(0.98, 1.02);
  }

  21% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  22% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  23% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  24% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  25% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  26% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  27% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  28% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  29% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  30% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  31% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  32% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  33% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  34% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  35% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  36% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  37% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  38% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  39% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  40% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  41% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  42% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  43% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  44% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  45% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  46% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  47% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  48% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  49% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}

@keyframes btnGroups {
  0% {
    -webkit-transform: scale(1.2, 0.8);
    -moz-transform: scale(1.2, 0.8);
    -ms-transform: scale(1.2, 0.8);
    transform: scale(1.2, 0.8);
  }

  1% {
    -webkit-transform: scale(1.18, 0.82);
    -moz-transform: scale(1.18, 0.82);
    -ms-transform: scale(1.18, 0.82);
    transform: scale(1.18, 0.82);
  }

  2% {
    -webkit-transform: scale(1.16, 0.84);
    -moz-transform: scale(1.16, 0.84);
    -ms-transform: scale(1.16, 0.84);
    transform: scale(1.16, 0.84);
  }

  3% {
    -webkit-transform: scale(1.13, 0.87);
    -moz-transform: scale(1.13, 0.87);
    -ms-transform: scale(1.13, 0.87);
    transform: scale(1.13, 0.87);
  }

  4% {
    -webkit-transform: scale(1.1, 0.9);
    -moz-transform: scale(1.1, 0.9);
    -ms-transform: scale(1.1, 0.9);
    transform: scale(1.1, 0.9);
  }

  5% {
    -webkit-transform: scale(1.07, 0.93);
    -moz-transform: scale(1.07, 0.93);
    -ms-transform: scale(1.07, 0.93);
    transform: scale(1.07, 0.93);
  }

  6% {
    -webkit-transform: scale(1.04, 0.96);
    -moz-transform: scale(1.04, 0.96);
    -ms-transform: scale(1.04, 0.96);
    transform: scale(1.04, 0.96);
  }

  7% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  8% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  9% {
    -webkit-transform: scale(0.97, 1.03);
    -moz-transform: scale(0.97, 1.03);
    -ms-transform: scale(0.97, 1.03);
    transform: scale(0.97, 1.03);
  }

  10% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  11% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  12% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  13% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  14% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  15% {
    -webkit-transform: scale(0.93, 1.07);
    -moz-transform: scale(0.93, 1.07);
    -ms-transform: scale(0.93, 1.07);
    transform: scale(0.93, 1.07);
  }

  16% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  17% {
    -webkit-transform: scale(0.94, 1.06);
    -moz-transform: scale(0.94, 1.06);
    -ms-transform: scale(0.94, 1.06);
    transform: scale(0.94, 1.06);
  }

  18% {
    -webkit-transform: scale(0.95, 1.05);
    -moz-transform: scale(0.95, 1.05);
    -ms-transform: scale(0.95, 1.05);
    transform: scale(0.95, 1.05);
  }

  19% {
    -webkit-transform: scale(0.96, 1.04);
    -moz-transform: scale(0.96, 1.04);
    -ms-transform: scale(0.96, 1.04);
    transform: scale(0.96, 1.04);
  }

  20% {
    -webkit-transform: scale(0.98, 1.02);
    -moz-transform: scale(0.98, 1.02);
    -ms-transform: scale(0.98, 1.02);
    transform: scale(0.98, 1.02);
  }

  21% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  22% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  23% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  24% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  25% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  26% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  27% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  28% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  29% {
    -webkit-transform: scale(1.03, 0.97);
    -moz-transform: scale(1.03, 0.97);
    -ms-transform: scale(1.03, 0.97);
    transform: scale(1.03, 0.97);
  }

  30% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  31% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  32% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  33% {
    -webkit-transform: scale(1.02, 0.98);
    -moz-transform: scale(1.02, 0.98);
    -ms-transform: scale(1.02, 0.98);
    transform: scale(1.02, 0.98);
  }

  34% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  35% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  36% {
    -webkit-transform: scale(1.01, 0.99);
    -moz-transform: scale(1.01, 0.99);
    -ms-transform: scale(1.01, 0.99);
    transform: scale(1.01, 0.99);
  }

  37% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  38% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  39% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }

  40% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  41% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  42% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  43% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  44% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  45% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  46% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  47% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  48% {
    -webkit-transform: scale(0.99, 1.01);
    -moz-transform: scale(0.99, 1.01);
    -ms-transform: scale(0.99, 1.01);
    transform: scale(0.99, 1.01);
  }

  49% {
    -webkit-transform: scale(1, 1);
    -moz-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
  }
}